<template>
	<div id="Combat">
		<div class="battle-top">
			<div class="icon-return" @click="$router.back()"></div>
			<div class="right">
				<div class="btn" v-if="$route.query.type == '2' || active=='3'" @click="openHistory">开箱记录</div>
			</div>
		</div>
		<Case :Detail="Detail" :game_arena_box = "game_arena_box" :Second="Second"></Case>
		<div class="vs_out" :class="['mode'+Detail.mode]">
			<Vsitem v-for="(item,index) in PlayerArr" :createUserId="Detail.create_user_id" :PlayerArr="PlayerArr" :ItemData="item" :game_arena_box="game_arena_box" :game_arena_id="game_arena_id" :status="status" :seat="index" :win_user="win_user" :mode="Detail.mode" :ban_npc="Detail.ban_npc" :close_type="Detail.close_type" :key="index" @setSecond="setSecond" ref="vsItem" @getActive="getActive"></Vsitem>
		</div>
		<div class="close-con" v-if="Detail.close_type == 1">
			规定时间内无人参与，系统自动关闭对战房间，费用已退回玩家账户
		</div>
		<van-overlay :show="animateStart">
			<div class="wrapper">
				<img class="fight-ani-start" v-if="animateStart" src="~@/assets/img/fight-animate.webp" alt="">
			</div>
		</van-overlay>
		<!-- <img class="fight-ani-start" v-if="animateStart" src="~@/assets/img/fight-animate.webp" alt=""> -->
		<van-overlay :show="openHashHistory">
			<div class="wrapper">
				<div class="hash-con">
					<div class="close" @click="openHashHistory=false"></div>
					<img class="title-img" src="~@/assets/img/hash-popup-title.png" alt="">
					<div class="title"><span>开箱历史</span></div>
					<div class="hash-user-list">
						<div class="item" :class="{'active': index == userIndex}" v-for="(item, index) in hashUser" :key="index" @click="userSelect(index)">
							<img :src="item.avatar" alt="">
							<div class="name">{{ item.name }}</div>
						</div>
					</div>
					<div class="list">
						<div class="item" v-for="(item,index) in hashRecord" :key="index">
							<div class="item-top">
								{{ item.created_at }}
								<div class="rate">回合：{{ index+1 }}/{{ hashRecord.length }}</div>
							</div>
							<div class="gun-info">
								<div class="img-con" :class="['lv'+item.lv]">
									<img :src="item.cover" alt="">
								</div>
								<div class="info">
									<div class="name">{{ item.name }}</div>
									<div class="alias">{{ item.quality_alias }} ({{ item.dura_alias }})</div>
								</div>
								<div class="btn-con">
									<div class="btn" @click="openHashUrl(1)">验证</div>
									<div class="btn" @click="openHashUrl(2)">16进制转换</div>
								</div>
							</div>
							<div class="seed-detail" v-if="item.hash_info">
								服务器种子HASH：{{ item.hash_info.server_seed_hash }}<br>
								服务器种子: {{ item.hash_info.server_seed }}<br>
								用户种子: {{ item.hash_info.user_seed }}<br>
								结果种子Hash:<br>
								{{ item.hash_info.hash }}<br>
								Number= {{ item.hash_info.divisor_hex }}(16进制) / ffffff(16进制) = <br>
								{{ item.hash_info.divisor }}/16777215 = <br>
								<span>{{ item.hash_info.result }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import Case from './components/Combat/Case.vue'//顶部信息栏
	import Vsitem from './components/Combat/Vsitem.vue'//对战模块
	import { ArenaDetail, boxGameRecords } from '@/network/api.js'
	import { mapState } from 'vuex'
	export default{
		name:'Combat',
		data(){
			return{
				Detail:{},//详情
				game_arena_box:[],//箱子列表
				status:0,//进程
				PlayerArr:[],//玩家列表
				game_arena_id:'',//对战id
				Second:1,//回合数
				win_user:[],//胜利玩家列表(有平局)
				resultShow: false,
				Awardlist: [],
				TotalPrice: 0, //回收总价格
				totalStatic: 0,
				animateStart: false,
				active: '1',
				openHashHistory: false,
				userIndex: 0,
				hashRecord: [],
				hashUser: []
			}
		},
		
		created() {
			this.GetArenaDetail()
		},
		
		methods:{
			openHashUrl(type){
				if(type==1){
					window.open('https://emn178.github.io/online-tools/sha256.html')
				}else{
					window.open('https://tool.oschina.net/hexconvert/')
				}
			},
			userSelect(index){
				this.userIndex = index
				this.hashRecord = this.hashUser[index].records
			},
			openHistory(){
				this.openHashHistory = true
				// let delay = localStorage.getItem('delay') ? Number(localStorage.getItem('delay')) : 0
				boxGameRecords({game_arena_id:this.$route.query.id, delay: 0}).then((res) => {
					this.hashUser = res.data.data.users
					this.hashRecord = res.data.data.users[0].records
				})
			},
			getActive(active){
				this.active = active
			},
			//对战详情
			GetArenaDetail(){
				ArenaDetail(this.$route.query.id).then((res) => {
					this.game_arena_id = res.data.data.id
					this.Detail = res.data.data
					this.game_arena_box = res.data.data.game_arena_box
					this.whether(res.data.data.status)
					this.operation(res.data.data.win_user_id)
					this.SeatScreening(res.data.data.user_num,res.data.data.game_arena_player)
					
				})
			},
			
			//确定座位顺序
			SeatScreening(num,arr){				
				let A_Arr = []
				for(let i = 0; i < num; i++){
					A_Arr[i] = null
				}
				
				for(let k = 0; k < arr.length; k++){
					A_Arr[arr[k].seat] = arr[k]
				}
				this.PlayerArr = A_Arr
			
			},
			
			//胜利玩家
			operation(v){
				if(v != null){
					this.win_user = v
				}
			},
			
			whether(v){
				if(this.$route.query.type == '2'){
					setTimeout(() =>{
						this.Second = this.game_arena_box.length
						this.status = 2
					},100)
				}else if(this.$route.query.type == '1'){
					this.animateStart = true
					setTimeout(() =>{
						this.animateStart = false
						this.status = 1
					},3000)
				}else{
					if(v==2){
						this.animateStart = true
						setTimeout(() =>{
							this.animateStart = false
							this.status = 1
						},3000)
					}else{
						setTimeout(() =>{
							this.status = v - 1
						},100)
					}
					
				}
			},
			
			setSecond(v){//回合数
				this.Second = v + 1
			}
		},
		
		computed:{
			...mapState([
				'join',
				'User'
			]),
		},
		
		watch:{
			join(v){//vuex监听属于本房价刷新数据
				if(v.game_arena_id == this.game_arena_id){
					this.GetArenaDetail()
				}
			}
		},
		
		components:{
			Case,
			Vsitem
		}
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	#Combat{
		padding: 10px;
		box-sizing: border-box;
		.vs_out{
			display: flex;
			margin-top: 10px;
			border-radius: 4px;
			overflow: hidden;
			padding: 2px;
			box-sizing: border-box;
			position: relative;
			gap: 4px;
		}
	}
	.fight-ani-start{
		width: 75px;
		height: 75px;	
	}
	
	.battle-top{
		position: relative;
		width: 352px;
		height: 34px;
		@include Scenter;
		margin-bottom: 6px;
		.icon-return{
			position: absolute;
			top: 50%;
			left: 0;
			width: 24px;
			height: 24px;
			background-size: 100% 100%;
			background-image: url(~@/assets/img/icon-return.png);
			cursor: pointer;
			transform: translateY(-50%);
		}
		.right{
			margin-left: auto;
			.btn{
				width: 82px;
				height: 34px;
				@include HScenter;
				color: #B2B2B2;
				background: linear-gradient(180deg, #1A1A20 0%, #191921 100%);
				cursor: pointer;
			}
		}
	}
	.hash-con{
		@include Scenter;
		flex-direction: column;
		padding-top: 15px;
		background-image: url(~@/assets/img/hash-popup.png);
		background-size: 100% 100%;
		width: 350px;
		height: 537px;
		position: relative;
		.close{
			width: 24px;
			height: 24px;
			background-image: url(~@/assets/img/rule-close.png);
			background-size: 100% 100%;
			position: absolute;
			right: 10px;
			top: 12px;
			cursor: pointer;
		}
		.title-img{
			height: 34px;
			margin-bottom: 12px;
		}
		.title{
			margin-bottom: 12px;
			span{
				position: relative;
				&::before{
					content: '';
					position: absolute;
					top: 50%;
					left: -110%;
					height: 1px;
					width: 53px;
					background: linear-gradient(270deg, #D9D9D9 50%, rgba(217, 217, 217, 0.00) 99.06%);
				}
				&::after{
					content: '';
					position: absolute;
					top: 50%;
					right: -110%;
					height: 1px;
					width: 53px;
					background: linear-gradient(90deg, #D9D9D9 50%, rgba(217, 217, 217, 0.00) 99.06%);
				}
			}
		}
		.label{
			width: 305px;
			margin-bottom: 12px;
		}
		.row{
			margin-bottom: 12px;
			width: 305px;
			@include Scenter;
			gap: 18px;
			.input{
				flex: 1;
				height: 32px;
				border: 1px solid; /* 设置一个基本的边框 */
				border-image: linear-gradient(to bottom, #448EFD, #44FDE7); /* 使用线性渐变作为边框图像 */
				border-image-slice: 1; /* 保持整个边框都是渐变色 */
				padding: 1px; /* 可选，用于确保内容不被边框覆盖 */
				padding: 1px 8px;
				input{
					width: 100%;
					height: 100%;
				}
			}
			.btn{
				background: #FFB800;
				width: 48px;
				height: 32px;
				color: #15161B;
				@include HScenter;
				cursor: pointer;
			}
		}
		.desc{
			width: 305px;
			height: 220px;
			white-space: pre-line;
			padding: 0 10px;
			overflow-y: auto;
			overflow-x: hidden;
			//滚动条宽度
			&::-webkit-scrollbar {
				width: 2px;
				height: 2px;
			}
			/*外层轨道。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果*/
			&::-webkit-scrollbar-track {
				background: rgba(255, 255, 255, 0.5);
			}
			//滚动条设置
			&::-webkit-scrollbar-thumb {
				// border-radius: 5px;
				background: rgba(255, 255, 255, 1);
			}
		}
		.hash-user-list{
			display: flex;
			width: 327px;
			margin: 0 auto 8px;
			gap: 18px;
			.item{
				width: 25%;
				@include Scenter;
				flex-direction: column;
				opacity: 0.5;
				cursor: pointer;
				&.active{
					opacity: 1;
				}
				img{
					width: 48px;
					height: 48px;
					margin-bottom: 8px;
					border-radius: 50%;
				}
				.name{
					@include wordhide;
					text-align: center;
					font-size: 12px;
					width: 100%;
				}
			}
		}
		.list{
			width: 327px;
			height: 340px;
			white-space: pre-line;
			padding: 0 10px;
			overflow-y: auto;
			overflow-x: hidden;
			//滚动条宽度
			&::-webkit-scrollbar {
				width: 2px;
				height: 2px;
			}
			/*外层轨道。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果*/
			&::-webkit-scrollbar-track {
				background: rgba(255, 255, 255, 0.5);
			}
			//滚动条设置
			&::-webkit-scrollbar-thumb {
				// border-radius: 5px;
				background: rgba(255, 255, 255, 1);
			}
			.item{
				.item-top{
					height: 28px;
					margin-bottom: 12px;
					@include Scenter;
					background: #2A2D3D;
					padding: 0 12px;
					font-size: 12px;
					color: #999;
					.rate{
						margin-left: auto;
						background: var(--LOGO, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
						background-clip: text;
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						font-size: 12px;
					}
				}
				.gun-info{
					@include Scenter;
					gap: 12px;
					.img-con{
						width: 72px;
						height: 65px;
						background-size: auto 100%;
						background-position: center center;
						background-repeat: no-repeat;
						&.lv1{
							background-image: url(~@/assets/img/product/roll-lv-bg-1.png);
						}
						&.lv2{
							background-image: url(~@/assets/img/product/roll-lv-bg-2.png);
						}
						&.lv3{
							background-image: url(~@/assets/img/product/roll-lv-bg-3.png);
						}
						&.lv4{
							background-image: url(~@/assets/img/product/roll-lv-bg-4.png);
						}
						&.lv5{
							background-image: url(~@/assets/img/product/roll-lv-bg-5.png);
						}
					}
					.info{
						flex: 1;
						overflow: hidden;
						.name{
							@include wordhide;
							font-size: 12px;
						}
						.alias{
							font-size: 12px;
							color: #999;
						}
					}
					.btn-con{
						.btn{
							width: 88px;
							height: 30px;
							background: #FFB800;
							color: #15161B;
							@include HScenter;
							cursor: pointer;
							&:first-child{
								margin-bottom: 8px;
							}
						}
					}
				}
				.seed-detail{
					font-size: 12px;
					word-break: break-all;
					margin: 12px 0;
					color: #999;
					span{
						color: #09FFC4;
					}
				}
			}
		}
	}
	.close-con{
		@include HScenter;
		font-size: 10px;
		height: 34px;
		color: #FFB800;
		border-radius: 2px;
		background: #292938;
		margin-top: 16px;
	}
</style>
