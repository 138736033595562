<template>
	<div id="Slidemod">
		<div class="Slide_out" :class="TranSition">
			<div class="Slide_item" v-for="(item,index) in GoodArr" :key="index" :data-index="index">
			 	<div class="img-con">
					<img :src="item.cover" alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Slidemod',

		props: {
			game_arena_box: { //宝箱信息
				type: Array
			},
			ItemData: { //玩家信息(中奖物品)
				type: Object
			},
			
			second:{
				type:Number
			},
			
			can:{//动画开始
				type:Boolean
			}
		},

		data() {
			return {
				Top: -50, //偏移值(初始)
				TranSition:'',//过度动画
				WiDth: 80, //宽度
				HeigHt: 80, //高度
				PadDing: 5, //内边距
				
				GoodArr: [], //处理后物品

			}
		},

		methods: {
			//处理物品(拼接并打乱)
			HandleGood() {
				let A_Arr = []
				for (let i = 0; i < 10; i++) {
					A_Arr = A_Arr.concat(this.game_arena_box[this.second].box_award)
				}

				// //打乱
				// function randSort(A_Arr) {
				// 	for (let i = 0, len = A_Arr.length; i < len; i++) {
				// 		let rand = parseInt(Math.random() * len)
				// 		let temp = A_Arr[rand]
				// 		A_Arr[rand] = A_Arr[i]
				// 		A_Arr[i] = temp
				// 	}
				// 	return A_Arr
				// }
				function shuffle (arr, num) {
				  let i, j, temp
				  while (num > arr.length) {
				    arr = arr.concat(arr)
				  }
				  arr = arr.slice(0, num)
				  for (i = arr.length - 1; i > 0; i--) {
				    j = Math.floor(Math.random() * (i + 1))
				    temp = arr[i]
				    arr[i] = arr[j]
				    arr[j] = temp
				  }
				  return arr
				}
				this.GoodArr = shuffle(A_Arr, 35)
				this.Determine()
			},

			//处理中奖
			Determine() {
				this.GoodArr[33] = this.ItemData.game_award[this.second].box_award //奖品挪移
				this.TranSition = 'active' //增加过度动画
				this.Top = -(this.WiDth * 32 + this.WiDth / 2 + this.PadDing * 2) //偏移值
				setTimeout(() => {
					this.sentence()
				}, 6900)
			},
			
			//子传父(是否继续)
			sentence(){
				if(this.second < this.game_arena_box.length - 1){
					this.$emit('setAnimation', true)
				}else{
					this.$emit('setAnimation', false)
				}
				
			}
			
		},

		watch: {
			can(v){//开始动画              
				if(v == true){
					this.TranSition = ''//删除过度动画
					this.Top = -50//复原偏移值
					setTimeout(() => {
						this.HandleGood()//重新触发
					},100)
				}
			}
		},

		computed: {
			//偏移样式
			TopSty() {
				return 'transform: translateY(' + this.Top + 'px)'
			},
		},
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	#Slidemod {
		width: 100%;
		height: 100%;
		overflow: hidden;
		-webkit-mask-image: linear-gradient(180deg, rgba(46, 56, 80, 0.0) 4%, #000 50%, rgba(46, 56, 80, 0.0) 96%);
		position: relative;
		&::before{
			content: '';
			position: absolute;
			left: 0;
			top: 59%;
			right: 0;
			height: 1px;
			background: #FFB800;
			z-index: 2;
		}
		.Slide_out {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			gap: 24px;
			&.active{
				transform: translateY(-2540px);
				transition: 6s cubic-bezier(0, 0, 0.28, 1);
			}
			.img-con {
				width: 90%;
				height: 56px;
				margin: 0 auto;
				
			}
		}
	}
</style>
