<template>
	<!-- <div id="Case">
		<div class="back" @click="RouTe">
			<van-icon name="revoke" color="#fff" size="30"/>
		</div>
		<div class="round">{{Second}}/{{Detail.box_num}}回合</div>
		<div class="box_list">
			<div class="box_item" v-for="(item,index) in game_arena_box" :key="index">
				<img :src="item.cover | ImgFilter" alt="">
			</div>
		</div>
		<div class="price">
			<img src="@/assets/images/public/Gold.png" alt="">{{Detail.total_bean}}
		</div>
	</div> -->
	<div class="case">
		<div class="number">
			回合：{{Second}}/{{Detail.box_num}}
		</div>
		<div class="price">
			<span>箱子总价</span>
			<div class="coins"></div>
			{{Detail.total_bean}}
		</div>
		<div class="box-list">
			<div class="item" v-for="(item,index) in game_arena_box" :key="item.id" @click="$router.push('/BoxDetail?id='+item.box_id)">
				<div class="img-con">
					<img :src="item.cover" alt="">
				</div>
				<div class="item_index" :class="{'active': Second==index+1}">
					<div class="line"></div>
					<span>{{ index+1 }}</span>
					<div class="line"></div>
				</div>
			</div>
		</div>
		<van-overlay :show="Show" :lock-scroll="false">
			<div class="wrapper">
				<div class="LogOut_box box_mod_out">
					<div class="close" @click="Show = false"><img style="filter: invert(1);" src="@/assets/images/public/close.png" alt=""></div>
					<div class="box_mod_top">
						<img :src="ArenaImg" alt="">
						<p>{{ArenaTitle}}</p>
					</div>
					<div class="mod_boxlist">
						<div class="mod_boxitem" v-for="(item,index) in ArenaArr" :key="index">
							<div class="mod_boxpic" :style="{'background-image': 'url('+item.lv_bg_image+')'}">
								<img :src="item.cover" alt="">
							</div>
							<p>{{item.name}}</p>
							<span><img src="@/assets/images/public/Gold.png" alt="">{{item.bean}}</span>
						</div>
					</div>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	export default{
		name:'Case',
		props:{
			Detail:{
				type:Object//对战详情
			},
			game_arena_box:{
				type:Array//箱子数量
			},
			Second:{//回合数
				type:Number
			}
		},
		data(){
			return{
				Show:false,//物品弹框
				ArenaTitle:'',//弹框标题
				ArenaImg:'',//弹框图片
				ArenaArr:[],//箱内物品列表
			}
		},
		
		methods:{
			RouTe(){
				this.$router.push('/Battle')
			},
			//箱内物品
			GetArenaArr(item){
				// console.log(item)
				this.ArenaTitle = item.name
				this.ArenaImg = item.cover
				this.ArenaArr = item.box_award
				this.Show = true
			}
		}
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	.case{
		width: 350px;
		margin: 0 auto 6px;
		position: relative;
		background: linear-gradient(180deg, #23232B 0%, #223 100%);
		padding: 0 14px 10px;
		.number{
			position: absolute;
			left: 4px;
			top: 4px;
			padding-left: 5px;
			height: 20px;
			line-height: 20px;
			background: #FFB800;
			color: #000;
			&::after{
				content: '';
				position: absolute;
				right: -20px;
				top: 0;
				border-style: solid;
				border-width: 10px;
				border-color: #FFB800 transparent transparent #FFB800;
			}
		}
		.price{
			@include Scenter;
			justify-content: flex-end;
			font-size: 14px;
			height: 32px;
			font-weight: bold;
			span{
				color: #bbb;
				font-size: 12px;
			}
			.coins{
				width: 14px;
				height: 14px;
				margin: 0 4px 0 12px;
			}
		}
		.box-list{
			display: flex;
			justify-content: center;
			gap: 6px;
			.item{
				.img-con{
					width: 48px;
					height: 48px;
					margin-bottom: 8px;
				}
				.item_index{
					position: relative;
					display: flex;
					align-items: center;
					color: #FFB800;
					&::before{
						content: '';
						position: absolute;
						left: 50%;
						top: 50%;
						width: 12px;
						height: 12px;
						border: 1px solid #FFB800;
						transform: translate(-50%, -50%) rotateZ(45deg);
					}
					&.active{
						color: #000;
						&::before{
							background: #FFB800;
						}
					}
					span{
						position: relative;
						z-index: 2;
						margin: 0 6px;
					}
					.line{
						flex: 1;
						height: 1px;
						background: #FFB800;
					}
				}
			}
		}
	}
	.box_mod_out{
			.box_mod_top{
				width: 100%;
				height: 34px;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #11151f;
				img{
					height: 34px;
					vertical-align: middle;
					margin-right: 10px;
				}
				p{
					font-size: 14px;
					color: #fff;
					font-weight: bold;
				}
			}
			.mod_boxlist{
				width: 100%;
				height: 400px;
				background: #20222b;
				border-radius:0 0 6px 6px;
				overflow-y: auto;
				padding:5px;
				box-sizing: border-box;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				.mod_boxitem{
					display: inline-block;
					width: 48%;
					margin-bottom: 10px;
					background: rgba($color: #fff, $alpha: .02);
					border-radius: 4px;
					text-align: center;
					padding-bottom: 10px;
					.mod_boxpic{
						width: 120px;
						height: 120px;
						margin: 0 auto;
						display: flex;
						align-items: center;
						justify-content: center;
						background-size: 70%;
						background-position: center;
						background-repeat: no-repeat;
						
						img {
							vertical-align: middle;
							max-width: 80%;
							max-height: 80%;
						}
					}
					p{
						font-size: 14px;
						padding: 0 8px;
						text-align: center;
						box-sizing: border-box;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						color: #fff;
					}
					span {
						display: block;
						margin-top: 5px;
						text-align: center;
						font-size: 14px;
						color: #75cd66;
						margin: 5px 0;
					
						img {
							width: 16px;
							height: 16px;
							vertical-align: middle;
							margin-bottom: 2px;
						}
					}
				}
				
			}
			
		}
</style>
